import { GetStaticProps } from 'next';
import Page, { getStaticProps as gsp } from './[...path]';

// export const config = {
//   unstable_includeFiles: ['../.next/static/chunks/**/*.js'],
// };

const getStaticProps: GetStaticProps = context => gsp(context);

export default Page;
export { getStaticProps };
